import React from 'react';

import { NotificationsListTypes } from 'App/reducer';

import { Wrapper } from './styles';
import Notification from './Notification';

const Notifications: React.FC<{ notifications: NotificationsListTypes[] }> = ({
  notifications,
}) => (
  <Wrapper>
    {notifications.map(({ notification, id }) => (
      <Notification
        key={`${id}${notification.code}`}
        code={notification.code}
        type={notification.type}
      />
    ))}
  </Wrapper>
);

export default Notifications;
