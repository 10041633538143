import styled from 'styled-components';
import { Form } from 'formik';

import { loginFormStyles, loginTitleStyles } from 'components/LoginWrapper/styles';

export const Content = styled(Form)`
  ${loginFormStyles};
`;

export const Title = styled.h1`
  ${loginTitleStyles};
`;

export const LoginFormActionsStyled = styled.div`
  width: 100%;
  text-align: center;
`;
