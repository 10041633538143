import React from 'react';
import { FormikProps } from 'formik';

import usePrefix from 'utils/usePrefix';
import colors from 'styles/colors';

import InputField from 'components/InputField';
import Button from 'components/Button';
import FlexDiv from 'components/FlexDiv';

import { FormValues } from '.';
import { Content, StyledLink, Title } from './styles';

const Form: React.FC<Pick<FormikProps<FormValues>, 'errors' | 'touched' | 'isSubmitting'>> = ({
  errors,
  touched,
  isSubmitting,
  ...props
}) => {
  const t = usePrefix('Login');

  return (
    <>
      <Content {...props} autoComplete="off">
        <Title>{t('set_password')}</Title>
        <InputField
          name="email"
          error={touched.email && errors.email}
          label={t('email')}
          placeholder={t('email_placeholder')}
          margin="15px 0 25px"
        />

        <FlexDiv flexDirection="column" alignItems="center">
          <Button label={t('send_link')} type="submit" disabled={isSubmitting} />
        </FlexDiv>
      </Content>
      <StyledLink to="/app" color={colors.brand_2}>
        {t('back_to_login')}
      </StyledLink>
    </>
  );
};

export default Form;
