import React from 'react';
import { FormikProps } from 'formik';

import usePrefix from 'utils/usePrefix';
import {
  passwordMinLength,
  upperRegex,
  lowerRegex,
  numberRegex,
  specialCharRegex
} from 'utils/validation';

import InputField from 'components/InputField';
import Button from 'components/Button';

import {
  Content,
  ButtonContainer,
  Title,
  RequirementsStyled,
} from './styles';
import Requirement from './Requirement';
import { FormValues } from '.';

const Form: React.FC<
  Pick<FormikProps<FormValues>, 'errors' | 'touched' | 'isSubmitting' | 'values'>
> = ({ errors, touched, isSubmitting, values, ...props }) => {
  const t = usePrefix('Login');

  return (
    <Content {...props} autoComplete="off">
      <Title>{t('set_password')}</Title>
      <InputField
        name="password"
        error={touched.password && !!errors.password}
        label={t('new_password')}
        type={'password'}
        placeholder={'••••••••••'}
        margin="15px 0 17px"
      />
      <InputField
        name="password_verify"
        error={touched.password_verify && errors.password_verify}
        label={t('confirm_password')}
        type={'password'}
        placeholder={'••••••••••'}
        margin="0 0 25px"
      />

      <ButtonContainer flexDirection="column" alignItems="center">
        <Button label={t('save_password')} type="submit" disabled={isSubmitting} />
      </ButtonContainer>

      <RequirementsStyled>
        {t('password_required')}
        <Requirement touched={!!values.password} error={values.password.length < passwordMinLength}>
          {t('password_chars')}
        </Requirement>
        <Requirement touched={!!values.password} error={!upperRegex.test(values.password)}>
          {t('password_upper')}
        </Requirement>
        <Requirement touched={!!values.password} error={!lowerRegex.test(values.password)}>
          {t('password_lower')}
        </Requirement>
        <Requirement touched={!!values.password} error={!numberRegex.test(values.password)}>
          {t('password_digit')}
        </Requirement>
        <Requirement touched={!!values.password} error={!specialCharRegex.test(values.password)}>
          {t('password_special_char')}
        </Requirement>
      </RequirementsStyled>
    </Content>
  );
};

export default Form;
