import React, { useMemo, useRef } from 'react';
import { FormikProps } from 'formik';
import { Editor } from '@tinymce/tinymce-react';
import { debounce } from 'lodash';

import usePrefix from 'utils/usePrefix';
import { noticeTypes, notificationTypes } from 'utils/constants';
import { useApp } from 'App';
import { getApiUrl, api, ResponseError } from 'utils/api';
import { ActionTypes } from 'App/types';

import RadioInput from 'components/RadioInput';
import Button from 'components/Button';
import DetailsTitle from 'components/DetailsTitle';
import FlexDiv from 'components/FlexDiv';
import AttachmentsList from 'components/AttachmentsList';

import FileUpload from './FileUpload';
import {
  Content,
  ButtonsContainer,
  FieldsContainer,
  StyledInputField,
  StyledFormField,
  EditorWrapper,
  EditorMessage,
  FieldTitle,
  Label,
} from './styles';
import { FormValues } from '.';

const url = getApiUrl('/notices/type');
const urlImages = getApiUrl('/notices/images');

const EDITOR_DEBOUNCE_TIME = 300;

interface Props {
  onClose: () => void;
  noticeId?: number;
  handleRefetchDetails: (value: boolean) => void;
}

const DetailsForm: React.FC<
  Pick<
    FormikProps<FormValues>,
    'errors' | 'touched' | 'isSubmitting' | 'values' | 'setFieldValue'
  > &
    Props
> = ({
  errors,
  touched,
  isSubmitting,
  onClose,
  values,
  setFieldValue,
  noticeId,
  handleRefetchDetails,
  ...props
}) => {
  const t = usePrefix('Notices');

  const editorRef = useRef<Editor>(null);
  const editorInitValue = useMemo(() => values.content, []);

  const [, dispatch] = useApp();

  const sendAttachment = async (formData: FormData) => {
    try {
      const response = await api(`${url}/${values.type}/${noticeId}/attachment`, {
        method: 'POST',
        formData,
      });

      if (response) {
        handleRefetchDetails(true);
        dispatch({
          type: ActionTypes.SET_NOTIFICATION_CODE,
          payload: { type: notificationTypes.success },
        });
      }
    } catch (error) {
      const typedError = error as ResponseError;
      dispatch({
        type: ActionTypes.SET_NOTIFICATION_CODE,
        payload: { code: typedError?.parsed?.code, type: notificationTypes.error },
      });
    }
  };

  const handleFilePicker = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0];
    if (!file) return;

    const formData = new FormData();
    formData.append('file', file);

    sendAttachment(formData);
  };

  const debouncedSearch = debounce(async (value: string) => {
    setFieldValue('content', value);
  }, EDITOR_DEBOUNCE_TIME);

  const handleEditorChange = async (value: string) => debouncedSearch(value);

  return (
    <Content {...props} autoComplete="off">
      <FlexDiv justifyContent="space-between" alignItems="center">
        <DetailsTitle title={values.name || t('name')} empty={values.name.length < 1} />
        <ButtonsContainer justifyContent="flex-end" alignItems="center">
          <Button onClick={onClose} color="link">
            {t('dont_save_changes')}
          </Button>
          <Button label={t('save_changes')} type="submit" disabled={isSubmitting} />
        </ButtonsContainer>
      </FlexDiv>
      <FlexDiv justifyContent="flex-start" alignItems="flex-start" alignContent="flex-start">
        <FieldsContainer>
          <StyledFormField label={t('type')} margin={'0'}>
            <RadioInput
              name="type"
              error={touched.type && !!errors.type}
              label={t('ADVICE')}
              value={noticeTypes.advice}
              disabled={!!noticeId}
            />
            <RadioInput
              name="type"
              error={touched.type && !!errors.type}
              label={t('INSTRUCTION')}
              value={noticeTypes.instruction}
              disabled={!!noticeId}
            />
            <RadioInput
              name="type"
              error={touched.type && !!errors.type}
              label={t('MOVIE')}
              value={noticeTypes.movie}
              disabled={!!noticeId}
            />
            <RadioInput
              name="type"
              error={touched.type && !!errors.type}
              label={t('ARTICLE')}
              value={noticeTypes.article}
              disabled={!!noticeId}
            />
          </StyledFormField>
          <div />
          <FlexDiv justifyContent="space-between" alignItems="flex-start">
            <StyledInputField name="name" error={touched.name && errors.name} label={t('name')} />
            <StyledInputField
              name="title"
              error={touched.title && errors.title}
              label={t('title')}
            />
          </FlexDiv>
          <FlexDiv justifyContent="space-between" alignItems="flex-start">
            <EditorWrapper error={!!touched.content && !!errors.content}>
              <FieldTitle justifyContent="flex-start" alignItems="flex-start">
                <Label>{t(`editor_content_${values.type}`)}</Label>
                {!!touched.content && !!errors.content && (
                  <EditorMessage>- {t('editor_message')}</EditorMessage>
                )}
              </FieldTitle>
              <Editor
                ref={editorRef}
                initialValue={editorInitValue}
                apiKey="56vsxxenclq2luy8soh45et6js0pqavwt4gfscr2z1t3bw6v"
                init={{
                  images_upload_url: urlImages,
                  menubar: false,
                  plugins: [
                    'advlist', 'autoresize', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview', 'anchor',
                    'searchreplace', 'visualblocks', 'code', 'fullscreen',
                    'insertdatetime', 'media', 'table',
                  ],
                  toolbar:
                    'undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | link image',
                }}
                onEditorChange={(val: string) => handleEditorChange(val)}
              />
              {!!values.attachments?.length && <AttachmentsList attachments={values.attachments} />}
              {!!noticeId && <FileUpload onChange={handleFilePicker} />}
            </EditorWrapper>
          </FlexDiv>
        </FieldsContainer>
      </FlexDiv>
    </Content>
  );
};

export default DetailsForm;
